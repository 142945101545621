import { render, staticRenderFns } from "./CompanyAndCustomer.vue?vue&type=template&id=21c2898c&scoped=true&"
import script from "./CompanyAndCustomer.vue?vue&type=script&lang=js&"
export * from "./CompanyAndCustomer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21c2898c",
  null
  
)

export default component.exports