<template>
  <div class="user-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="customer-relationship/customer/page">
      <el-button
        v-if="$auth('客户人员新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open(0)">
        {{$l("common.add", "新增")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('customer.customerCompanyName','客户公司')">
            <el-input v-model.trim="filter.customerCompanyName" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('user.name','姓名')">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('user.phone','手机号')">
            <el-input v-model.trim="filter.phone" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <div slot="tree" :style="{width:width+'px'}" class="treeBox">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
      </div>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="customerCompanyName" :label="$l('customer.customerCompanyName','客户公司')" align="center"></el-table-column>
      <el-table-column prop="realEstateName" :label="$l('realEstate.realEstateName','楼盘名称')" align="center">
        <template slot-scope="scope">
          <span v-for="(item,index) in scope.row.realEstates" :key="index">{{item.name}};</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" :label="$l('user.name','姓名')" align="center"></el-table-column>
      <el-table-column prop="phone" :label="$l('user.phone','手机号')" align="center"></el-table-column>
      <el-table-column prop="gender" label="性别" align="center">
        <template slot-scope="scope">
          {{scope.row.gender==="male"?"男":'女'}}
        </template>
      </el-table-column>
      <el-table-column prop="post" :label="$l('address.post','职位')" align="center"></el-table-column>
      <el-table-column prop="roleName" label="用户角色" align="center">
        <template slot-scope="scope">
          <span v-for="(item,index) in scope.row.roles" :key="index">{{item.name}};</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" :label="$l('address.remark','备注')" align="center"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button v-if="$auth('客户人员编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button v-if="$auth('客户人员删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </div>
</template>
<script>
  import EditPage from "./Edit.vue";
  import {getWidth} from "@/util";

  export default {
    components: {EditPage},
    data() {
      return {
        filter: {
          name: "",
          contactName: "",
          contactPhone: "",
          provinceCode: "",
          cityCode: "",
          customerCompanyName:"",
          areaCode: "",
        },
        data:[],
        width:200,
      };
    },
    mounted() {
      this.getList(1);
      this.getTreeList();
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("/customer-relationship/customer", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/basic/district/tree/locate-and-with-customer-company");
        http.then(data => {
          this.data = data;
          this.width=100+14*getWidth(data);
        }).catch(() => {
          this.status = "error";
        });
      },
      handleNodeClick(e) {
        if (e.other==="customerCompany") {
          this.filter.customerCompanyName=e.label;
          this.getList(-1);
        }

      },
    },
  };
</script>
<style lang="scss" scoped>
.treeBox{
  border: 1px solid #E6E6E6;
  padding: 10px;
  margin-right: 10px;
  width: calc(100% - 1300px);
  max-height: 750px;
  overflow-y: auto;
}
</style>
