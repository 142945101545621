<template>
  <el-tabs value="first" @tab-click="handleClick">
    <el-tab-pane v-if="$auth('客户档案客户公司')" label="客户公司" name="first"><list></list></el-tab-pane>
    <el-tab-pane v-if="$auth('客户档案客户人员')" label="客户人员" name="second"><customer-list></customer-list></el-tab-pane>
  </el-tabs>
</template>

<script>
  import List from "@/views/elevatorUsingCompany/List";
  import CustomerList from "@/views/elevatorUsingCustomer/List";
  export default {
    components:{List,CustomerList},
  };
</script>

<style scoped>

</style>